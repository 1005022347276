import { disableIconsTypes, enableIconsTypes } from '@/api/pmiconstype';

const disableIconsType = {
	id: 'disableIconsType',
	selectionType: 'multiple',
	label: 'pmiconstype.actions.disableIconsType',
	functionality: 'UPDATE_PMICONSTYPE',
	checkAvailability: function (iconstype) {
		return iconstype && iconstype.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmiconstype.actions.disableIconsType');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmiconstypeid: registries[i].pmiconstypeid
			});
		}

		const data = await disableIconsTypes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableIconsType = {
	id: 'enableIconsType',
	selectionType: 'multiple',
	label: 'pmiconstype.actions.enableIconsType',
	functionality: 'UPDATE_PMICONSTYPE',
	checkAvailability: function (iconstype) {
		return iconstype && iconstype.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmiconstype.actions.enableIconsType');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmiconstypeid: registries[i].pmiconstypeid
			});
		}

		const data = await enableIconsTypes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableIconsType, enableIconsType]
};
